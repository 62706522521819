import React, { useContext, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CRMContext, CRMProvider } from "./context/CRMContext";
import "./App.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Menu from "./components/menu/Menu";
import NotFound from "./components/generics/NotFound";

const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const Barrios = lazy(() => import("./components/barrios/Barrios"));
const NuevoBarrio = lazy(() => import("./components/barrios/NuevoBarrio"));
const MostrarBarrio = lazy(() => import("./components/barrios/MostrarBarrio"));
const EditarBarrio = lazy(() => import("./components/barrios/EditarBarrio"));
const Lotes = lazy(() => import("./components/lotes/Lotes"));
const AgregarLotes = lazy(() => import("./components/lotes/AgregarLotes"));
const EditarLote = lazy(() => import("./components/lotes/EditarLote"));
const EditarLotesMasivo = lazy(() => import("./components/lotes/EditarLotesMasivo"));
const ExportarLotes = lazy(() => import("./components/lotes/ExportarLotes"));
const NuevoCliente = lazy(() => import("./components/clientes/NuevoCliente"));
const ListadoClientes = lazy(() => import("./components/clientes/ListadoClientes"));
const EditarCliente = lazy(() => import("./components/clientes/EditarCliente"));
const ListadoOperaciones = lazy(() => import("./components/operaciones/ListadoOperaciones"));
const Operacion = lazy(() => import("./components/operaciones/Operacion"));
const NuevaOperacion = lazy(() => import("./components/operaciones/nuevaOperacion/components/NuevaOperacion"));
const Login = lazy(() => import("./components/login/login"));
const ReadquisicionLotes = lazy(() => import("./components/readquisicion/ReadquisicionLotes"));
const ListadoReadquisicionLotes = lazy(() => import("./components/readquisicion/ListadoReadquisicionLotes"));
const EditarReadquisicionLotes = lazy(() => import("./components/readquisicion/EditarReadquisicionLotes"));
const ListadoConfirmarOperaciones = lazy(() => import("./components/operaciones/ListadoConfirmarOperaciones"));
const ListadoInmobiliarias = lazy(() => import("./components/inmobiliarias/ListadoInmobiliarias"));
const NuevaInmobiliaria = lazy(() => import("./components/inmobiliarias/NuevaInmobiliaria"));
const EditarInmobiliaria = lazy(() => import("./components/inmobiliarias/EditarInmobiliaria"));
const ListadoUsuarios = lazy(() => import("./components/usuarios/ListadoUsuarios"));
const NuevoUsuario = lazy(() => import("./components/usuarios/NuevoUsuario"));
const EditarUsuario = lazy(() => import("./components/usuarios/EditarUsuario"));
const ListadoEscribanias = lazy(() => import("./components/escribanias/ListadoEscribanias"));
const NuevaEscribania = lazy(() => import("./components/escribanias/NuevaEscribania"));
const EditarEscribania = lazy(() => import("./components/escribanias/EditarEscribania"));
const ListadoNacionalidades = lazy(() => import("./components/nacionalidades/listadoNacionalidades"));
const NuevaNacionalidad = lazy(() => import("./components/nacionalidades/nuevaNacionalidad"));
const EditarNacionalidad = lazy(() => import("./components/nacionalidades/editarNacionalidad"));
const ListadoEstadoCivil = lazy(() => import("./components/estadoCivil/listadoEstadoCivil"));
const EditarEstadoCivil = lazy(() => import("./components/estadoCivil/editarEstadoCivil"));
const NuevoEstadoCivil = lazy(() => import("./components/estadoCivil/nuevoEstadoCivil"));
const Bienes = lazy(() => import("./components/bienes/Bienes"));
const Bien = lazy(() => import("./components/bienes/Bien"));
const AgregarBien = lazy(() => import('./components/bienes/AgregarBien'));
const ParametrosListado = lazy(() => import("./components/parametros/parametrosListado"));
const Parametro = lazy(() => import("./components/parametros/parametro"));
const ListadoFirmantesLife = lazy(() => import("./components/firmantesLife/ListadoFirmantesLife"));
const FormularioFirmantesLife = lazy(() => import("./components/firmantesLife/FormularioFirmantesLife"));
const ListaDePrecio = lazy(() => import("./components/listasDePrecios/ListaDePrecio"));
const ListadoBloqueos = lazy(() => import("./components/bloqueos/ListadoBloqueos"));
const NuevoBloqueo = lazy(() => import("./components/bloqueos/NuevoBloqueo"));
const EditarFirmantes = lazy(() => import("./components/operaciones/components/EditarFirmantes"));
const ExcelUploader = lazy(() => import("./components/lotes/EditarMasivos"));
const AltaMasivaLotes = lazy(() => import("./components/lotes/AltaMasiva"));
const ListadoEstadosOperaciones = lazy(() => import("./components/estadosOperaciones/listado"));
const FormularioEditarEstado = lazy(() => import("./components/estadosOperaciones/formulario"));
const ListadoLotesFormaPago = lazy(() => import("./components/lotes/ListadoLotesFormaPago"));
const Logs = lazy(() => import("./components/logs/Logs"));
const ListadoMotivosDisponibilidad = lazy(() => import("./components/motivosDisponibilidad/listado"));
const FormularioEditarMotivo = lazy(() => import("./components/motivosDisponibilidad/formulario"));
const FormularioAltaMotivo = lazy(() => import("./components/motivosDisponibilidad/formularioAlta"));
const FormularioCrearLote = lazy(() => import("./components/lotes/CrearLote/CrearLote"));
const FormularioDocumentacion = lazy(() => import("./components/documentacion/FormularioDocumentacion"));
const ListadoDocumentacion = lazy(() => import("./components/documentacion/ListadoDocumentacion"));
const ListadoCategorias = lazy(() => import("./components/documentacion/ListadoCategorias"));
const FormularioCategoria = lazy(() => import("./components/documentacion/FormularioCategoria"));
const FormularioAltaInversionista = lazy(() => import("./components/inversionistas/formularioAlta"));
const ListadoInversionistas = lazy(() => import("./components/inversionistas/listado"));
const FormularioEditarInversionista = lazy(() => import("./components/inversionistas/formularioEditar"));
const CalendarioFirmas = lazy(() => import("./components/operaciones/CalendarioFirmas/CalendarioFirmas"));
const FormularioAlta = lazy(() => import("./components/notarial/AltaCesiones/FormularioAlta"));
const ListadoDeCesiones = lazy(() => import("./components/notarial/AltaCesiones/ListadoCesiones"));
const ListadoPrecios = lazy(() => import("./components/listaDePrecios/ListaDePrecios"));
const ListadoListaDePrecios = lazy(() => import("./components/listaDePrecios/ListadoListaDePrecios"));
const VerListaDePrecios = lazy(() => import("./components/listaDePrecios/VerListaDePrecios"));
const ShowExistentClient = lazy(() => import("./components/clientes/new/ShowExistentClient"));
const EditarListaDePrecios = lazy(() => import("./components/listaDePrecios/EditarListaDePrecios"));
const MostrarCesion = lazy(() => import("./components/notarial/AltaCesiones/MostrarCesion"));
const VerLote = lazy(() => import("./components/lotes/VerLote"));
const VerCliente = lazy(() => import("./components/clientes/VerCliente"));
const StockBarrios = lazy(() => import("./components/barrios/components/StockBarrios"));
const DatabaseBackupRestore = lazy(() => import("./components/database/DatabaseExportRestore"));
const VerProveedor = lazy(() => import("./components/stock/proveedores/VerProveedor"));
const ListadoProveedores = lazy(() => import("./components/stock/proveedores/ListadoProveedores"));
const EditarProveedor = lazy(() => import("./components/stock/proveedores/EditarProveedor"));
const ListadoCanjes = lazy(() => import("./components/stock/canjes/ListadoCanjes"));
const VerCanje = lazy(() => import("./components/stock/canjes/VerCanje"));
const NuevoCanje = lazy(() => import("./components/stock/canjes/NuevoCanje"));

function App() {
  const [auth, guardarAuth] = useContext(CRMContext);
  console.log(process.env.REACT_APP_BACKEND_URL);

  return (
    <Router>
      <CRMProvider value={[auth, guardarAuth]}>
        <div className='wrapper'>
          <Header/>
          <Menu/>

          <div className='content-wrapper'>
            <div className='content-header'>
              <div className='container-fluid'>
                <div className='row mb-2'></div>
              </div>
            </div>
            <section className='content'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-12'>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Switch>
                        <Route exact path='/login' component={Login}/>
                        <Route exact path='/' component={Dashboard}/>
                        <Route exact path='/barrios' component={Barrios}/>
                        <Route exact path='/barrios/stock/:id' component={StockBarrios}/>
                        <Route exact path='/barrios/nuevo' component={NuevoBarrio}/>
                        <Route exact path='/barrios/editar/:id' component={EditarBarrio}/>
                        <Route exact path='/barrios/ver/:id' component={MostrarBarrio}/>
                        <Route exact path="/lotes/nuevo" component={FormularioCrearLote}/>
                        <Route exact path="/lotes/entregados" component={ListadoLotesFormaPago}/>
                        <Route exact path='/lotes/altaMasiva/:id' component={AltaMasivaLotes}/>
                        <Route exact path='/lotes/masivo/:id' component={ExcelUploader}/>
                        <Route exact path='/lotes/agregar' component={AgregarLotes}/>
                        <Route exact path='/lotes/editar' component={EditarLotesMasivo}/>
                        <Route exact path='/lotes/exportar' component={ExportarLotes}/>
                        <Route exact path='/lotes/editar/:id' component={EditarLote}/>
                        <Route exact path='/lotes/ver/:id' component={VerLote}/>
                        <Route exact path='/lotes/:id' component={Lotes}/>
                        <Route exact path='/lotes' component={Lotes}/>
                        <Route exact path='/clientes/nuevo' component={NuevoCliente}/>
                        <Route exact path='/clientes/editar/:id' component={EditarCliente}/>
                        <Route exact path='/clientes/ver/:id' component={VerCliente}/>
                        <Route exact path='/clientes' component={ListadoClientes}/>
                        <Route exact path='/operaciones/calendarioFirmas' component={CalendarioFirmas}/>
                        <Route exact path='/operaciones/editarFirmantes/:id' component={EditarFirmantes}/>
                        <Route exact path='/operaciones' component={ListadoOperaciones}/>
                        <Route exact path='/operaciones/nueva' component={NuevaOperacion}/>
                        <Route exact path='/operaciones/readquisicion' component={ListadoReadquisicionLotes}/>
                        <Route exact path='/operaciones/readquisicion/nueva' component={ReadquisicionLotes}/>
                        <Route exact path='/operaciones/readquisicion/:id' component={EditarReadquisicionLotes}/>
                        <Route exact path='/operaciones/confirmar' component={ListadoConfirmarOperaciones}/>
                        <Route exact path='/operaciones/:id' component={Operacion}/>
                        <Route exact path='/configuracion/inmobiliarias' component={ListadoInmobiliarias}/>
                        <Route exact path='/configuracion/inmobiliarias/nueva' component={NuevaInmobiliaria}/>
                        <Route exact path='/configuracion/inmobiliarias/editar/:id' component={EditarInmobiliaria}/>
                        <Route exact path='/configuracion/usuarios' component={ListadoUsuarios}/>
                        <Route exact path='/configuracion/usuarios/nuevo' component={NuevoUsuario}/>
                        <Route exact path='/configuracion/usuarios/editar/:id' component={EditarUsuario}/>
                        <Route exact path='/configuracion/escribanias' component={ListadoEscribanias}/>
                        <Route exact path='/configuracion/escribanias/nueva' component={NuevaEscribania}/>
                        <Route exact path='/configuracion/escribanias/editar/:id' component={EditarEscribania}/>
                        <Route exact path='/configuracion/nacionalidades' component={ListadoNacionalidades}/>
                        <Route exact path='/configuracion/nacionalidades/nueva' component={NuevaNacionalidad}/>
                        <Route exact path='/configuracion/nacionalidades/editar/:id' component={EditarNacionalidad}/>
                        <Route exact path='/configuracion/estadoCivil' component={ListadoEstadoCivil}/>
                        <Route exact path='/configuracion/estadoCivil/nuevo' component={NuevoEstadoCivil}/>
                        <Route exact path='/configuracion/estadoCivil/editar/:id' component={EditarEstadoCivil}/>
                        <Route exact path={'/bienes'} component={Bienes}/>
                        <Route exact path={'/bienes/nuevo'} component={AgregarBien}/>
                        <Route exact path={'/bienes/:id'} component={Bien}/>
                        <Route exact path={'/configuracion/parametros'} component={ParametrosListado}/>
                        <Route exact path={'/configuracion/parametros/:id'} component={Parametro}/>
                        <Route exact path={'/configuracion/firmantesLife'} component={ListadoFirmantesLife}/>
                        <Route exact path={'/configuracion/firmantesLife/nuevo'} component={FormularioFirmantesLife}/>
                        <Route exact path={`/configuracion/firmantesLife/editar/:id`} component={FormularioFirmantesLife}/>
                        <Route exact path={'/configuracion/listaPrecios/:id'} component={ListaDePrecio}/>
                        <Route exact path={'/bloqueos/nuevo'} component={NuevoBloqueo}/>
                        <Route exact path={'/bloqueos'} component={ListadoBloqueos}/>
                        <Route exact path="/estadosOperaciones/listado" component={ListadoEstadosOperaciones}/>
                        <Route exact path="/estadosOperaciones/editar/:id" component={FormularioEditarEstado}/>
                        <Route exact path="/motivoDisponibilidad/listado" component={ListadoMotivosDisponibilidad}/>
                        <Route exact path="/motivoDisponibilidad/editar/:id" component={FormularioEditarMotivo}/>
                        <Route exact path="/motivoDisponibilidad/nuevo" component={FormularioAltaMotivo}/>
                        <Route exact path="/configuracion/inversionistas/listado" component={ListadoInversionistas}/>
                        <Route exact path="/configuracion/inversionistas/editar/:id" component={FormularioEditarInversionista}/>
                        <Route exact path="/configuracion/inversionistas/nuevo" component={FormularioAltaInversionista}/>
                        <Route exact path="/documentacion/categorias/listado" component={ListadoCategorias}/>
                        <Route exact path="/documentacion/categorias/nuevo" component={FormularioCategoria}/>
                        <Route exact path="/documentacion/nuevo" component={FormularioDocumentacion}/>
                        <Route exact path="/documentacion/editar/:id" component={FormularioDocumentacion}/>
                        <Route exact path='/documentacion/archivosAdjuntos' component={ListadoDocumentacion}/>
                        <Route exact path='/logs' component={Logs}/>
                        <Route exact path='/altaCesion' component={FormularioAlta}/>
                        <Route exact path='/listadoCesiones' component={ListadoDeCesiones}/>
                        <Route exact path='/listadoCesiones/:id' component={MostrarCesion}/>
                        <Route exact path='/listaDePrecios/nuevo' component={ListadoPrecios}/>
                        <Route exact path='/listaDePrecios/editar/:id' component={EditarListaDePrecios}/>
                        <Route exact path='/listaDePrecios/:id' component={VerListaDePrecios}/>
                        <Route exact path='/listaDePrecios' component={ListadoListaDePrecios}/>
                        <Route exact path='/concepto/cliente/' component={ShowExistentClient}/>
                        <Route exact path='/database' component={DatabaseBackupRestore}/>
                        <Route exact path='/proveedores/listado' component={ListadoProveedores}/>
                        <Route exact path='/proveedores/editar/:id' component={EditarProveedor}/>
                        <Route exact path='/proveedores/:id' component={VerProveedor}/>
                        <Route exact path='/canjes/listado' component={ListadoCanjes}/>
                        <Route exact path='/canjes/nuevo' component={NuevoCanje}/>
                        <Route exact path='/canjes/:id' component={VerCanje}/>
                        <Route path="*" component={NotFound} />
                      </Switch>
                    </Suspense>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <Footer/>
        </div>
      </CRMProvider>
    </Router>
  );
}

export default App;
